import React from 'react'
import banner  from './img/arthbanner.jpg'

const Layout = () => {
    return (
        <div className='layout'>

       <img src={banner}/>
        </div>
    )
}

export default Layout