import React from 'react'
import Section from './Section';
import Footer from './Footer';
const About = () => {
  return (
    <>

<center><h1>About Us </h1></center>
    <Section/>
    <Footer/>
    
    </>
  )
}

export default About
