import React from 'react'
import ser1 from './img/hip.jpg';
import ser2 from './img/orthopadic.jpg';
import ser3 from './img/knee.jpg';
import ser4 from './img/orthoscopy.jpg';
import ser5 from './img/plastic.jpg';
import ser6 from './img/spine.jpg';
import ser7 from './img/joint.jpg';
import ser8 from './img/musle.jpg';

const Services = () => {
    return (
        <>

            <br />

            <center><h1>Our Treatments</h1></center>

            <div className='services'>

            <div className='services2'>
                    <img src={ser2} />
                    <h1>Orthopedics</h1>
                    <p>orthopedics, medical specialty concerned with the preservation and restoration of function of the skeletal system and its associated structures, i.e., spinal and other bones, joints, and muscles. orthopedics. Also called: orthopedic surgery.</p>
                </div>
                <div className='services2'>
                    <img src={ser1} />
                    <h1>Hip Replacement</h1>
                    <p>How long does it take to walk normally after hip surgery? This depends on your general health and fitness, as well as the condition of your joints and muscles. However, in most cases, you will be able to walk without a walking aid after 10 to 14 days.</p>
                </div>
               
                <div className='services2'>
                    <img src={ser3} />
                    <h1>Knee Replacement</h1>
                    <p>Knee arthroplasty is a safe, effective procedure that helps people regain their mobility and relieves long-term pain. Most people who have a knee replacement have reduced pain, increased ability to move and use their knee, and improved quality of life.</p>
                </div>
                <div className='services2'>
                    <img src={ser4} />
                    <h1>Arthroscopy</h1>
                    <p>Arthroscopy (ahr-THROS-kuh-pee) is a procedure for diagnosing and treating joint problems. A surgeon inserts a narrow tube attached to a fiber-optic video camera through a small incision — about the size of a buttonhole.</p>
                </div>
            </div>

            <div className='services'>
                {/* <div className='services2'>
                    <img src={ser6} />
                    <h1>Spine Surgery</h1>
                    <p>Spinal decompression and fusion: It is the most common spine surgery performed in the U.S., and it's performed to take pressure off of “pinched” nerves and/or the spinal cord and to stabilize the spine.</p>
                </div> */}
                <div className='services2'>
                    <img src={ser8} />
                    <h1>Muscle pain</h1>
                    <p>The most common causes of muscle pain are tension, stress, overuse and minor injuries. This type of pain is usually limited to just a few muscles or a small part of your body. Muscle pain that is felt throughout your whole body is most often caused by an infection, such as the flu.</p>
                </div>
                <div className='services2'>
                    <img src={ser7} />
                    <h1>Joint pain</h1>
                    <p>Joint pain can be caused by many types of injuries or conditions. It may be linked to arthritis, bursitis, and muscle pain. No matter what causes it, joint pain can be very bothersome.</p>
                </div>
                {/* <div className='services2'>
                    <img src={ser5} />
                    <h1>Plastic Surgery</h1>
                    <p>plastic surgery, the functional, structural, and aesthetic restoration of all manner of defects and deformities of the human body.</p>
                </div> */}
            </div>

        </>
    )
}

export default Services


