import React from 'react'
import { Link } from 'react-router-dom';
import about from './img/satyam.jpg';

const Section = () => {
    return (
        <>

            <div className='section'>
                <div className='section2'>
                    <h1> Welcome To Dr. Satyam Singh</h1>
                    <p>Hello I am Dr Satyam Singh,as a highly skilled and experienced orthopaedic surgeon, I specialize in the latest techniques and technologies to restore function and alleviate pain in patients with musculoskeletal conditions. My expertise lies in arthroscopy, arthroplasty, and complex trauma, allowing me to tackle a wide range of cases, from sports injuries and joint degeneration to severe fractures and traumatic injuries. With a passion for staying at the forefront of medical innovation, I continually update my knowledge and skills to provide the most effective and minimally invasive treatments for my patients.</p>
                    <p>My approach is centered around personalized care, compassion, and open communication, ensuring that each patient receives the highest level of attention and care. Whether it's repairing a torn ligament, replacing a damaged joint, or reconstructing a complex fracture, I am dedicated to helping patients regain their strength, mobility, and quality of life."</p>
                    <Link to='/Register'><button>Contact Us</button></Link>


                </div>
                <div className='section2'>
                    <img src={about} />
                   
                </div>
            </div>


            <div class="icon-bar">
                <a href="Tel: 8318118641" class="facebook"><i class="fa fa-phone"></i></a>
                <a  href="https://wa.me/8318118641"  class="twitter"><i class="fa fa-whatsapp"></i></a>
               
            </div>

        </>
    )
}

export default Section