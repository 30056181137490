import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './component/Media.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import Navbar from './component/Navbar';
import Home from './component/Home';
import Register from './component/Register';
import About from './component/About.js';
import Gallery from './component/Gallery.js';
import Videogallery from './component/Videogallery.js';



function App() {
  return (
    <div className='main'>

      <Router>
        <Navbar />
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/About" element={<About />} />
          <Route path="/Videogallery" element={<Videogallery />} />
          <Route path="/Gallery" element={<Gallery />} />
        </Routes>
      </Router>

    </div>

  );
}

export default App;
