import React from 'react'
import Slider from './Slider';
import Section from './Section';
import Services from './Services';
import Describe from './Describe';
import Footer from './Footer';
import Layout from './Layout';


const Home = () => {
  return (
    <>
      <div className='main'>
        <Slider />
        <Section />
        <br/>
        <Layout />
        <Services />
        <Describe />
        <Footer />
      </div>
    </>
  )
}

export default Home