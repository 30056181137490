import React from 'react';
import Swal from 'sweetalert2';
import Footer from './Footer';

const Register = () => {
  const sendWhatsAppMessage = (data) => {
    const phoneNumber = "+919958929194";
    const message = `
      Name: ${data.name}
      Email: ${data.email}
      Age: ${data.age}
      Mobile: ${data.mobile}
      Gender: ${data.gender}
      Appointment Date: ${data.appointmentDate}
      Address: ${data.address}
      Description: ${data.description}
    `;
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    sendWhatsAppMessage(data);
  };

  return (
    <>
    <br/>
      <center><h1>Book Appointment </h1></center>
      <br/>
      <div className='container'>
        <form className='mt-5' onSubmit={handleSubmit}>
          <div className='row'>
            <div className="mb-3 col-lg-6 col-12">
              <label htmlFor="name" className="form-label">Name</label>
              <input type="text" name="name" className="form-control" id="name" required />
            </div>

            <div className="mb-3 col-lg-6 col-12">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" name="email" className="form-control" id="email" required />
            </div>

            <div className="mb-3 col-lg-6 col-12">
              <label htmlFor="age" className="form-label">Age</label>
              <input type="number" name="age" className="form-control" id="age" required />
            </div>

            <div className="mb-3 col-lg-6 col-12">
              <label htmlFor="mobile" className="form-label">Mobile</label>
              <input type="text" name="mobile" className="form-control" id="mobile" required />
            </div>

            <div className="mb-3 col-lg-6 col-12">
              <label htmlFor="gender" className="form-label">Gender</label>
              <select name="gender" className="form-control" id="gender" required>
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div className="mb-3 col-lg-6 col-12">
              <label htmlFor="appointmentDate" className="form-label">Appointment Date</label>
              <input type="date" name="appointmentDate" className="form-control" id="appointmentDate" required />
            </div>

            <div className="mb-3 col-lg-12">
              <label htmlFor="address" className="form-label">Address</label>
              <input type="text" name="address" className="form-control" id="address" required />
            </div>

            <div className="mb-3 col-lg-12">
              <label htmlFor="description" className="form-label">Description</label>
              <textarea name="description" className="form-control" id="description" rows="3" required></textarea>
            </div>

            <button type="submit" className="btn btn-primary">Submit</button>
          </div>
        </form>
      </div>

      <br />
      <Footer />
    </>
  );
}

export default Register;
