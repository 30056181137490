import React from 'react';
import Footer from './Footer';
import vid from './video/video.mp4';


const Videogallery = () => {
  return (
    <>
        <center><h1>Our Videos</h1></center>

      <div className="video-container">
        <video className="responsive-video" controls autoplay>
          <source src={vid} type="video/mp4" />
          
        </video>
      </div>
      <Footer />
    </>
  );
}

export default Videogallery;
