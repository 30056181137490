import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const Navbar = () => {
    const [expanded, setExpanded] = useState(false);

    const handleNavItemClick = () => {
        setExpanded(false);
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg ">
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand  " >
                        <h1 className='logo'> Dr. Satyam Singh</h1>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setExpanded(!expanded)}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse ${expanded ? 'show' : ''}`} id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to="/" className="nav-link" onClick={handleNavItemClick}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/About" className="nav-link" onClick={handleNavItemClick}>About</Link>
                            </li>


                            <li className="nav-item dropdown">
                                <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">Treatments</Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">

                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Orthopedics</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Hip Replacement</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Knee Replacement</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Arthroscopy</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Muscle pain</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Joint pain</Link></li>

                                </ul>
                            </li>


                            <li className="nav-item dropdown">
                                <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">Media</Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
                                    <li><Link to="/Gallery" className="dropdown-item" onClick={handleNavItemClick}>Photos</Link></li>
                                    <li><Link to="/Videogallery" className="dropdown-item" onClick={handleNavItemClick}>Videos</Link></li>

                                </ul>
                            </li>



                            <li className="nav-item">
                                <Link to="/Register" className="nav-link" onClick={handleNavItemClick}>Book Appointment </Link>
                            </li>



                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
