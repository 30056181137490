import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Banner1 from './img/banner1.jpg';
import Banner2 from './img/banner2.jpg';
import Banner3 from './img/banner3.jpg';

const Slider = () => {
   

    return (
        <>
            <div className='carousal'>
                <div className='slide1'>
                    <Carousel>
                        <Carousel.Item className='slides'>
                            <img src={Banner1} alt="First slide" />
                            {/* <Carousel.Caption>
                                <h3>First slide label</h3>
                                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                            </Carousel.Caption> */}
                        </Carousel.Item>
                        <Carousel.Item className='slides'>
                            <img src={Banner2} alt="Second slide" />
                            {/* <Carousel.Caption>
                                <h3>Second slide label</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </Carousel.Caption> */}
                        </Carousel.Item>
                        <Carousel.Item className='slides'>
                            <img src={Banner3} alt="Third slide" />
                            {/* <Carousel.Caption>
                                <h3>Third slide label</h3>
                                <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                            </Carousel.Caption> */}
                        </Carousel.Item>
                    </Carousel>
                </div>

                <div className='slide2'>
                    <h1>Orthopaedic</h1>
                    <marquee direction="up" >
                       
                        <p>Orthopedics</p>
                        <p>Hip Replacement</p>
                        <p>Knee Replacement</p>
                        <p>Arthroscopy</p>
                        <p>Muscle pain</p>
                        <p>Joint pain</p>
                    </marquee>
                </div>
            </div>
        </>
    );
};

export default Slider;
