import React from 'react';
import Footer from './Footer';
import gal1 from './img/hip.jpg';
import gal2 from './img/joint.jpg';
import gal3 from './img/knee.jpg';
import gal4 from './img/musle.jpg';
import gal5 from './img/orthoscopy.jpg';
import gal6 from './img/orthopadic.jpg';


import raj1 from './img/raj (1).png';
import raj2 from './img/raj (2).png';
import raj3 from './img/raj (3).png';
import raj4 from './img/raj (4).png';
import raj5 from './img/raj (5).png';
import raj6 from './img/raj (6).png';
import raj7 from './img/raj (7).png';
import raj8 from './img/raj (8).png';
import raj9 from './img/raj (9).png';
import raj10 from './img/raj (10).png';

const Gallery = () => {
  return (
    <>

      <center><h1>Our Gallery</h1></center>
      <div className="gallery-container">

        <div className="gallery">
          <img src={raj1} alt="Hip" />
          <img src={raj2} alt="Joint" />
          <img src={raj3} alt="Knee" />
          <img src={raj4} alt="Muscle" />
          <img src={raj5} alt="Orthoscopy" />

        </div>
      </div>



      <div className="gallery-container">

        <div className="gallery">
          <img src={gal1} alt="Hip" />
          <img src={gal2} alt="Joint" />
          <img src={gal3} alt="Knee" />
          <img src={gal4} alt="Muscle" />
          <img src={gal5} alt="Orthoscopy" />

        </div>
      </div>



      <div className="gallery-container">

        <div className="gallery">
          <img src={raj6} alt="Hip" />
          <img src={raj7} alt="Joint" />
          <img src={raj8} alt="Knee" />
          <img src={raj9} alt="Muscle" />
          <img src={raj10} alt="Orthoscopy" />

        </div>
      </div>

      <Footer />
    </>
  );
};

export default Gallery;
