import React from 'react'

const Describe = () => {
  return (
    <div className='describe'>
        <h1>Call Us for Orthopaedic Consultant</h1>
        <p>+91 8318118641</p>
    </div>
  )
}

export default Describe