import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
    return (

<>

        <div className='footer'>

            <div className='foot'>   
                  
                <h1>Follow Us On </h1>
                <a href=""><i class="fa fa-facebook-f"></i> </a>
                <a href=""><i class="fa fa-instagram"></i> </a>
                <a href=""><i class="fa fa-youtube-play"></i> </a>
               

                <h1>Opening Hours</h1>
                <h4>Chandan Hospital </h4>
                <p>09:00 AM To 5:00 PM</p>
                <h4> Arth Vardhan Multispeciality Clinic </h4>
                <p> Mon - Sat : -  05:00 PM To 08:00PM <br/>
                    Sunday 11:00 AM To 01:00PM
                
                </p>
                
            </div>

            <div className='foot'>
                <h1>Useful Links</h1>
                <br /><br />
                <p><Link to='/' class='color'>Home</Link></p>
                <p><Link to='/' class='color'>About</Link></p>
                <p><Link to='/' class='color'>Treatment</Link></p>
                <p><Link to='/' class='color'>Contact</Link></p>
                <p><Link to='/' class='color'>Gallery</Link></p>
            </div>
            <div className='foot'>
                <h1>Connect Width Us </h1>
                <br /><br />
                <p><a href=''><i class="fa fa-phone"></i> +91 8318118641</a></p>
                <p><a href=''> <i class="fa fa-envelope"></i> maytas29@gmail.com</a></p>
                <p><a href=''> <i class="fa fa-map-marker"></i> Arth Vardhan
                    Multispeciality Clinic , beside
                    Chinmay Resort,  opp. Ahmamau petrol pump lucknow.</a></p>

            </div>

            <div className='foot'>
                <h1>Location</h1>
                <br />
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3562.25677032216!2d80.9456269!3d26.768083899999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfb78041db227%3A0x86fb742e0ecd1c1f!2sRaibareli%20Rd%2C%20Lucknow%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1714975876873!5m2!1sen!2sin" style={{ width: '95%', height: '70%' }}></iframe>
            </div>

        </div>


        <div className='disclamer'>
            <h1> @2024 Dr. Satyam Singh Developed & Designed By Creative Digital World </h1>
        </div>

        </>
    )
}

export default Footer